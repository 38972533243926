import {
    Create,
    Edit,
    FormTab,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    FormDataConsumer,
    BooleanInput,
    NumberInput,
    required,
    email,
    minValue,
    regex
} from 'react-admin'
import { Grid } from '@material-ui/core';

import {getApplicationSpecName} from "./utils";
import {JSONInput} from "../components/JsonFieldInput";
import {DateTimeInput} from '../components/date-time-pickers/';
import {useFormState} from 'react-final-form';
import ToolbarWithoutDelete from "../components/ToolbarWithoutDelete";
import EditionAndSlugInput from './EditionAndSlugInput';
import ApplicationScopeInput from './ApplicationScopeInput';
import IsEditableInput from './IsEditableInput';
import applicationSchema from './schemas/applicationSchema';
import confirmationSchema from './schemas/confirmationSchema';


const EMPTY_OBJ = {};

const ApplicationSpecTitle = ({ record }) => {
    return <span>A editar candidatura "{getApplicationSpecName(record)}"</span>;
};

const ApplicationFormat = (props) => {
    ApplicationFormat.defaultProps = { source: 'questions', fullWidth: true, addLabel: true };
    const isIterationScoped = useFormState().values.application_scope === 'iteration';
    return <JSONInput schema={applicationSchema} disabled={isIterationScoped} {...props} />;
}

const ConfirmationFormat = (props) => {
    ConfirmationFormat.defaultProps = { source: 'confirmation_format', fullWidth: true, addLabel: true };
    return <JSONInput schema={confirmationSchema} defaultValue={EMPTY_OBJ} {...props} />;
}

const DateInputs = (props) => {
    DateInputs.defaultProps = { fullWidth: true }
    const isIterationScoped = useFormState().values.application_scope === 'iteration';
    return <>
        <DateTimeInput fullWidth options={{disabled: isIterationScoped}} source="start_date" {...props} />
        <DateTimeInput fullWidth options={{disabled: isIterationScoped}} source="end_date" {...props} />
    </>
}

const emailSenderValidation = [required(), email()];
const daysValidation = [required(), minValue(1)];
const urlValidation = regex(/^https?:\/\/.+\..+/, "Deve ser um URL válido")

const CommunicationTabContent = (props) => (
    <Grid container spacing={2}>
        <Grid item md={12}>
            <TextInput fullWidth
                source="email_sender"
                type="email"
                validate={emailSenderValidation}
                helperText="resources.application_specs.helpers.email_sender"
                resource={props.resource}
            />  
        </Grid>
        <Grid item xs={12} md={9}>
            <TextInput
                fullWidth
                validate={urlValidation}
                source="email_logo_url"
                helperText="resources.application_specs.helpers.email_logo_url"
                resource={props.resource}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <SelectInput
                fullWidth
                source="email_templates"
                helperText="resources.application_specs.helpers.email_templates"
                resource={props.resource}
                defaultValue="default"
                choices={["default", "afterschool", "alpha", "atlas", "participar"].map(d => ({id: d, name: d}))}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <NumberInput
                fullWidth
                source="days_until_results"
                validate={daysValidation}
                helperText="resources.application_specs.helpers.days_until_results"
                resource={props.resource}
                min={1}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <NumberInput
                fullWidth
                source="days_for_confirmation"
                validate={daysValidation}
                helperText="resources.application_specs.helpers.days_for_confirmation"
                resource={props.resource}
                min={1}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <BooleanInput 
                source="send_email_followups"
                helperText="resources.application_specs.helpers.send_email_followups"
                resource={props.resource}
            />
        </Grid>
        <Grid item xs={12} md={3}>
            <FormDataConsumer>
                {({formData}) => (
                    <BooleanInput 
                        source="send_sms_followups"
                        helperText="resources.application_specs.helpers.send_sms_followups"
                        resource={props.resource}
                        disabled={!formData.send_email_followups}
                        defaultValue={props.record?.send_sms_followups ?? formData.send_email_followups}
                    />
                )}
            </FormDataConsumer>
        </Grid>
        <Grid item xs={12} md={3}>
            <BooleanInput 
                source="send_logins_on_confirmation"
                helperText="resources.application_specs.helpers.send_logins_on_confirmation"
                resource={props.resource}
            />
        </Grid>
    </Grid>
);

const editionForm = edit => (
    <TabbedForm toolbar={<ToolbarWithoutDelete />}>
        <FormTab label="resources.application_specs.edition_tab_general">
            {edit &&
                <TextInput fullWidth disabled source="id" />
            }
            <ReferenceInput fullWidth source="program_id" reference="programs" sort={{ field: 'name', order: 'ASC' }}>
                <SelectInput optionText="name" validate={required()} />
            </ReferenceInput>
            <EditionAndSlugInput />
            <ApplicationScopeInput />
            <IsEditableInput />
            <DateInputs />
        </FormTab>
        <FormTab label="resources.application_specs.edition_tab_application">
            <ApplicationFormat />
        </FormTab>
        <FormTab label="resources.application_specs.edition_tab_confirmation">
            <ConfirmationFormat />
        </FormTab>
        <FormTab label="resources.application_specs.edition_tab_communication">
            <CommunicationTabContent />
        </FormTab>
    </TabbedForm>
);

export const ApplicationSpecEdit = props => (
    <Edit {...props} title={<ApplicationSpecTitle />}>
        {editionForm(true)}
    </Edit>
);

export const ApplicationSpecCreate = props => (
    <Create {...props}>
        {editionForm(false)}
    </Create>
);
