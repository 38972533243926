module.exports = {
    ra: {
        action: {
            add_filter: 'Adicionar Filtro',
            add: 'Adicionar',
            back: 'Voltar',
            bulk_actions: '1 item selecionado |||| %{smart_count} itens selecionados',
            cancel: 'Cancelar',
            clear_input_value: 'Limpar campo',
            clone: 'Duplicar',
            confirm: 'Confirmar',
            create: 'Novo',
            create_item: 'Novo %{item}',
            delete: 'Apagar',
            edit: 'Editar',
            export: 'Exportar',
            list: 'Listar',
            refresh: 'Atualizar',
            remove_filter: 'Remover filtro',
            remove: 'Excluir',
            save: 'Guardar',
            search: 'Pesquisar',
            show: 'Mostrar',
            sort: 'Ordenar',
            undo: 'Desfazer',
            unselect: 'Desmarcar',
            expand: 'Expandir',
            close: 'Fechar',
            open_menu: 'Abrir menu',
            close_menu: 'Fechar menu',
            update: 'Atualizar',
        },
        boolean: {
            true: 'Sim',
            false: 'Não',
            null: ' ',
        },
        page: {
            create: 'Novo %{name}',
            dashboard: 'Painel de Controlo',
            edit: '%{name} #%{id}',
            error: 'Ocorreu um erro',
            list: 'Listar %{name}',
            loading: 'Carregando',
            not_found: 'Não encontrado',
            show: '%{name} #%{id}',
            empty: 'Ainda não há nenhum registo em %{name}',
            invite: 'Criar um novo?',
        },
        input: {
            file: {
                upload_several:
                    'Arraste alguns arquivos para fazer upload, ou clique para selecioná-los.',
                upload_single:
                    'Arraste o arquivo para fazer upload, ou clique para selecioná-lo.',
            },
            image: {
                upload_several:
                    'Arraste algumas imagens para fazer upload ou clique para selecioná-las',
                upload_single:
                    'Arraste um arquivo para upload ou clique em selecionar arquivo.',
            },
            references: {
                all_missing: 'Não foi possível encontrar os dados das referências.',
                many_missing:
                    'Pelo menos uma das referências passadas já não está disponível.',
                single_missing:
                    'A referência passada aparenta já não estar disponível.',
            },
            password: {
                toggle_visible: 'Esconder palavra-passe',
                toggle_hidden: 'Mostrar palavra-passe',
            },
        },
        message: {
            about: 'Sobre',
            are_you_sure: 'Tem a certeza?',
            bulk_delete_content:
                'Tem a certeza que deseja excluir este %{name}? |||| Tem a certeza que deseja excluir estes %{smart_count} itens?',
            bulk_delete_title:
                'Excluir %{name} |||| Excluir %{smart_count} %{name} itens',
            bulk_update_content:
                'Tem a certeza que quer atualizar este %{name}? |||| Tem a certeza que quer atualizar estes %{smart_count} itens?',
            bulk_update_title:
                'Atualizar %{name} |||| Atualizar %{smart_count} %{name}',
            delete_content: 'Tem a certeza que deseja apagar?',
            delete_title: 'Apagar %{name} #%{id}',
            details: 'Detalhes',
            error: 'Ocorreu um erro e não foi possível concluir o seu pedido.',
            invalid_form:
                'Este formulário não está valido. Corrija por favor os erros.',
            loading: 'A página está a carregar. Um momento, por favor',
            no: 'Não',
            not_found:
                'Foi digitada um URL inválido, ou o link pode estar quebrado.',
            yes: 'Sim',
            unsaved_changes:
                'Algumas das suas alterações não foram guardadas, deseja realmente ignorá-las?',
        },
        navigation: {
            no_results: 'Nenhum resultado encontrado',
            no_more_results:
                'A página número %{page} está fora dos limites. Tente a página anterior.',
            page_out_of_boundaries: 'Página %{page} fora do limite',
            page_out_from_end: 'Não é possível ir para lá da última página',
            page_out_from_begin: 'Não é possível ir para uma página anterior à primeira',
            page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
            page_rows_per_page: 'Resultados por página:',
            next: 'Seguinte',
            prev: 'Anterior',
            skip_nav: 'Saltar para o conteúdo',
        },
        sort: {
            sort_by: 'Ordenar por %{field} %{order}',
            ASC: 'crescente',
            DESC: 'decrescente',
        },
        auth: {
            auth_check_error: 'Por favor, faça login para continuar',
            user_menu: 'Perfil',
            username: 'Utilizador',
            password: 'Palavra-passe',
            sign_in: 'Entrar',
            sign_in_error: 'Erro na autenticação, tente novamente.',
            logout: 'Sair',
        },
        notification: {
            updated:
                'Item atualizado com sucesso |||| %{smart_count} itens foram atualizados com sucesso',
            created: 'Item criado com sucesso',
            deleted:
                'Item removido com sucesso! |||| %{smart_count} itens foram removidos com sucesso',
            bad_item: 'Item incorreto',
            item_doesnt_exist: 'Esse item já não existe',
            http_error: 'Erro na comunicação com o servidor',
            data_provider_error: 'Erro interno do servidor.',
            i18n_error:
                'Não foi possível carregar as traduções para o idioma especificado',
            canceled: 'Ação cancelada',
            logged_out: 'A sua sessão foi terminado. Por favor, volte a fazer login.',
        },
        validation: {
            required: 'Obrigatório',
            minLength: 'Deve ter no mínimo %{min} caracteres',
            maxLength: 'Deve ter no máximo %{max} caracteres',
            minValue: 'Deve ser %{min} ou maior',
            maxValue: 'Deve ser %{max} ou menor',
            number: 'Deve ser um número',
            email: 'Deve ser um email válido',
            oneOf: 'Deve ser uma das seguintes opções: %{options}',
            regex: 'Deve ter o formato específico (regexp): %{pattern}',
        },
    },
    resources: {
        iterations: {
            view_live_button: 'Ver Live',
            preview_button: 'Pré-visualizar',
            copy_staff_emails_button: 'Copiar emails',
            copy_staff_emails_message: 'Emails copiados!',
            add_to_team_button: 'Adicionar à Equipa',
            add_weekly_sessions_button: 'Adicionar sessões semanais',
            send_selection_emails_button: 'Enviar emails de seleção',
            added_to_team: 'Adicionados à equipa com sucesso!',
            sent_selection_emails: "Emails de seleção enviados!",
            program_selection_dialog_title: "Selecionar Programa",
            name: "Iteração |||| Iterações",
            application_spec_selection_dialog_title: "Selecionar Candidatura",
            fields: {
                id: "ID",
                course_id: "Curso",
                iteration_name: "Iteração",
                old_id: "ID AfterSchool",
                image_id: "Imagem",
                start_date: "Data de Início",
                end_date: "Data de Fim",
                applications_start_date: "Início das Candidaturas",
                applications_end_date: "Fim das Candidaturas",
                scholarity: "Anos de Escolaridade",
                location: "Localização",
                location_text: "Descrição de Localização",
                short_description: "Descrição curta",
                description: "Descrição detalhada",
                topics: "Tópicos",
                additional_info: "Informações adicionais",
                side_info: "Informação do menu lateral (lista de objetos com 'title' e 'value')",
                faq: "FAQ (lista de objetos com 'title' e 'value')",
                application_questions: "Perguntas da Candidatura",
                forum_category_id: "Fórum",
                discord_server_id: "Discord",
                edx_id: "ID do LMS",
                work_before_first: "Há trabalho no LMS antes da primeira sessão",
                applications_nr: "#Candidaturas",
                students_nr: "#Alunos",
                calendar_status: "Estado",
                shift: "Turno",
                shift_schedule: "Horário do Turno",
                application_spec_id: "Candidatura",
                "course.program.name": "Programa",
                "course.program.slug": "Programa",
                "application_spec.edition": "Edição",
            }
        },
        discords: {
            copied: 'Copiado!',
            new_found: 'Novo servidor discord encontrado!',
            not_found: 'Não foi possível encontrar o servidor, por favor selecione manualmente.',
        },
        forums: {
            created: 'Fórum criado!'
        },
        application_specs: {
            name: "Especificação de Candidatura |||| Especificação de Candidaturas",
            submissions: "Submissões",
            iterations: "Iterações",
            full_name: "Nome",
            send_rejection_emails_button: "Enviar emails de rejeição",
            sent_rejection_emails: "Emails de rejeição enviados!",
            edition_tab_general: "Geral",
            edition_tab_application: "Formato Candidatura",
            edition_tab_confirmation: "Formato Confirmação",
            edition_tab_communication: "Comunicação",
            iteration_scoped: "Candidaturas a iterações",
            fields: {
                id: "ID",
                program_id: "Programa",
                edition: "Edição",
                slug: "Slug",
                questions: "Perguntas da Candidatura",
                confirmation_format: "Formato da Confirmação",
                start_date: "Início das Candidaturas",
                end_date: "Fim das Candidaturas",
                is_editable: "Candidatura editável",
                email_sender: "Email de envio",
                email_templates: "Templates",
                email_logo_url: "URL logo",
                days_until_results: "Dias até resultados",
                days_for_confirmation: "Dias para confirmação",
                send_email_followups: "Enviar follow-ups",
                send_sms_followups: "Enviar SMS de follow-up",
                send_logins_on_confirmation: "Enviar logins ao confirmar",
                "program.name": "Programa",
                "program.slug": "Programa",
            },
            helpers: {
                iteration_scoped: "Se selecionado, as candidaturas são direcionadas a iterações (datas e formato de candidatura são definidos na iteração).",
                is_editable: "Se selecionado, o candidato pode atualizar a sua submissão durante o período de candidaturas; não é possível modificar uma submissão já processada (rejeitada / associada a iteração).",    
                email_sender: "O endereço de onde os emails são enviados",
                email_templates: "Templates usados nos emails e SMS",
                email_logo_url: "URL da imagem enviada nos emails",
                days_until_results: "Número de dias entre o fecho das candidaturas e a divulgação dos resultados. Este prazo é indicado ao aluno após submissão da candidatura.",
                days_for_confirmation: 'Número de dias disponíveis para o aluno confirmar a candidatura a partir da receção do email de seleção.',
                send_email_followups: "Se selecionado, são enviados emails de follow-up para os alunos e encarregados de educação, o primeiro no dia seguinte à data limite da confirmação.",
                send_sms_followups: "Se selecionado, são enviadas SMS juntamente com os emails de follow-up.",
                send_logins_on_confirmation: "Se selecionado, o email com dados de login nas plataformas é automaticamente enviado ao aluno após este confirmar a candidatura.",
            }
        },
        application_submissions: {
            name: "Submissão de Candidatura |||| Submissões de Candidaturas",
            assigned_iterations: "Iterações",
            assign_iterations_button: "Atribuir Iterações",
            no_allowed_iterations: "Não há iterações atribuíveis a esta candidatura",
            error_getting_allowed_iterations: "Ocorreu um erro ao obter as iterações atribuíveis",
            assign_iteration_success: "Iteração atribuída com sucesso",
            assign_iteration_error: "Ocorreu um erro ao atribuir a iteração",
            reject_button: 'Rejeitar candidaturas',
            reject_success: 'Candidaturas rejeitadas com sucesso!',
            reject_error: 'Erro ao rejeitar as candidaturas.',
            fields: {
                application_spec_id: "Candidatura",
                user_id: "ID(s) de utilizador(es)",
                name: "Nome",
                school_district: "Distrito",
                school_name: "Escola",
                school_year: "Ano",
                school_group: "Agrupamento",
                school_location: "Localidade",
                phone: "Telemóvel",
                parent_name: "Nome Encarregado(a) de Educação",
                parent_email: "Email Encarregado(a) de Educação",
                parent_phone: "Telemóvel Encarregado(a) de Educação",
                birthdate: "Data de nascimento",
                gender: "Género",
                nationality: "Nacionalidade",
                history_t2: "Atividades T2",
                history_t2_app: "Candidaturas T2",
                history_oc: "Olimpíadas Científicas",
                info_interests: "Interesses",
                info_english: "Nível de Inglês",
                info_computer: "Acesso a Computador",
                info_internet: "Acesso à Internet",
                created_at: "Data de submissão",
                questions: "Respostas",
                rejected: "Rejeitado",
                rejection_email_sent: "E-mail rejeição"
            }
        },
        applications: {
            approve_button: 'Aprovar candidaturas',
            approved_success: 'Candidaturas aprovadas com sucesso!',
            approved_error: 'Erro ao aprovar as candidaturas.',
            reject_button: 'Rejeitar candidaturas',
            reject_success: 'Candidaturas rejeitadas com sucesso!',
            reject_error: 'Erro ao rejeitar as candidaturas.',
            send_student_logins_success: 'Dados de acesso enviados com sucesso!',
            send_student_logins_button: 'Enviar logins manualmente',
            name: "Candidatura |||| Candidaturas",
            fields: {
                name: "Nome",
                school_district: "Distrito",
                school_name: "Escola",
                school_year: "Ano",
                school_group: "Agrupamento",
                school_location: "Localidade",
                accepted: "Selecionado",
                confirmed: "Confirmado",
                course_iter_id: 'Iteração',
                user_id: 'ID(s) de utilizador(es)',
                created_at: 'Data de submissão',
                email: 'Email',
                phone: "Telemóvel",
                parent_name: "Nome Encarregado(a) de Educação",
                parent_email: "Email Encarregado(a) de Educação",
                parent_phone: "Telemóvel Encarregado(a) de Educação",
                birthdate: "Data de nascimento",
                gender: "Género",
                nationality: "Nacionalidade",
                history_t2: "Atividades T2",
                history_t2_app: "Candidaturas T2",
                history_oc: "Olimpíadas Científicas",
                info_interests: "Interesses",
                info_english: "Nível de Inglês",
                info_computer: "Acesso a Computador",
                info_internet: "Acesso à Internet",
                id_card_image: "Documento de Identificação",
                donation_amount: "Valor da Doação",
                donation_receipt: "Comprovativo da Doação",
                vat_number: "Número de Contribuinte do Aluno",
                confirmation_obs: "Observações da Confirmação",
                comment: "Comentário",
                recommendation: "Recomendado",
                selection_email: "Email de seleção",
                logins_email: "Email de logins",
                "course_iteration.application_spec_id": "Candidatura",
                "course_iteration/application_spec_id": "Candidatura"
            }
        },
        donations: {
            name: "Doação |||| Doações",
            send_receipts_success: 'Faturas enviadas com sucesso!',
            send_receipts_button: 'Enviar fatura',
            validated_success: 'Doações validadas com sucesso!',
            validated_error: 'Erro ao validar as doações.',
            validate_button: 'Validar doações',
            fields: {
                name: "Nome",
                course_iter_id: 'Iteração',
                email: 'Email',
                user_id: 'Email',
                phone: "Telemóvel",
                parent_name: "Nome Encarregado(a) de Educação",
                parent_email: "Email Encarregado(a) de Educação",
                parent_phone: "Telemóvel Encarregado(a) de Educação",
                donation_validated: "Validada",
                invoice_sent: "Enviada",
                payment_amount: "Valor",
                payment_receipt: "Comprovativo de Pagamento",
                payment_vat_number: "Número de Contribuinte do Aluno",
                confirmation_remarks: "Observações da Confirmação",
                updated_at: "Atualizado a",
                updated_at_lte: 'Antes de',
                updated_at_gte: 'Depois de',
                payment_amount_gt: 'Pagamento superior a',
                payment_amount_lt: 'Pagamento inferior a',
                "course_iteration/application_spec_id": "Candidatura"
            }
        },
        courses: {
            name: "Curso |||| Cursos",
            fields: {
                id: "ID",
                name: "Nome",
                slug: "Slug",
                short_slug: "Slug Curto",
                program_id: "Programa" ,
                iterations: "Iterações",
                min_staff_members: 'Dimensão mínima da equipa',
                possible_months: 'Meses possíveis',
                "program.name": "Programa",
                "program.slug": "Programa",
            }
        },
        sessions: {
            name: "Sessão |||| Sessões",
            fields: {
                id: "ID",
                session_number: "Sessão#",
                session_start: "Início da Sessão",
                session_end: "Fim da Sessão",
                course_iter_id: "Iteração",
                participants_nr: "#Participantes",
                is_online: "Online",
            },
            set_present_success: 'Marcado(s) como presente com sucesso!',
            set_present_error: 'Erro ao marcar como presente.',
            set_absent_success: 'Marcado(s) como ausente com sucesso!',
            set_absent_error: 'Erro ao marcar como ausente.',
            bulk_added: 'Sessões adicionadas com sucesso.'
        },
        session_participants: {
            fields: {
                name: "Nome",
                session_number: "Sessão#",
                present: 'Presente',
            },
        },
        staff_users: {
            fields: {
                name: "Nome",
                user_id: "Email",
                phone: "Telemóvel",
                course_iter_id: 'Iteração',
                roles: 'Tipo de Contributos',
                validated: 'Última submissão do instrutor foi validada',
                description: 'Descrição',
                place_born: 'Local de Nascimento',
                place_residence: 'Local de Residência',
                academic_experience: 'Formação académica e experiências profissionais/académicas',
                other_interests: 'Interesses para além da área profissional/académica',
                interesting_fact: 'Facto interessante',
                image_id: 'Imagem atual',
                updated_at: 'Atualizado',
                created_at: 'Submetido',
            }
        },
        staff_users_contacts: {
            fields: {
                name: 'Nome',
                email: 'Email',
                phone: 'Telemóvel',
                courses: 'Participações'
            }
        },
        participants: {
            send_certificates_success: 'Certificados enviados com sucesso!',
            send_certificates_button: 'Enviar certificados',
            fields: {
                alunos: 'Aluno',
                course_iter_id: 'Iteração',
                in_discord: 'No discord',
                invite_code: 'Invite pessoal discord',
                discord_user_id: 'User ID Discord',
                name: 'Nome',
                user_id: 'Email',
                attendances: 'Presenças'
            }
        },
        sent_emails: {
            fields: {
                sent_at_lt: 'Antes de',
                sent_at_gt: 'Depois de',
                subject: 'Assunto',
                to: 'Para',
                cc: 'CC',
                sent_at: 'Data de envio',
            }
        },
        tasks: {
            fields: {
                date_done_lt: 'Antes de',
                date_done_gt: 'Depois de',
                status: 'Estado',
                name: 'Tarefa',
                date_done: 'Data',
            }
        },
        users: {
            merge_successful: 'Os utilizadores foram fundidos com sucesso',
            merge_button: 'Fundir utilizadores',
            fields: {
                id: 'ID',
                email: 'Email',
                name: 'Nome',
                auth_userid: 'ID auth0',
                staff_roles_nr: '#Equipa',
                participations_nr: '#Participações',
                applications_nr: '#Candidaturas',
                instructor_status: "Habilitação para instrutor"
            }
        }
    },
};
