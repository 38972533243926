import {
    BooleanField,
    Datagrid,
    FilterLiveSearch,
    FunctionField,
    List,
    NullableBooleanInput,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import * as React from "react";
import PrefixedUrlField from "../components/PrefixedUrlField";
import AttendancesField from "../components/AttendancesField";
import {TextArrayField} from "../components/TextArrayField";
import BigPagination from "../components/BigPagination";
import BulkSendCertificatesButton from "./BulkSendCertificatesButton";
import {getIterationName} from "../iterations/utils";
import IterationFilter from "../components/IterationFilter";


export const ParticipantsBulkActionButtons = (props) => (
    <React.Fragment>
        <BulkSendCertificatesButton {...props} />
    </React.Fragment>
);

export const participantsFilters = [
    <FilterLiveSearch source="q" alwaysOn/>,
    <NullableBooleanInput source="alunos" alwaysOn/>,
    <IterationFilter source="course_iter_id" />,
    <NullableBooleanInput source="in_discord"/>,
];

const ParticipantExpand = (props) => (
    <Show
        {...props}
        title=" "
    >
        <SimpleShowLayout>
            <PrefixedUrlField source="invite_code" prefix="https://discord.gg/"/>
            <TextField source="discord_user_id"/>
        </SimpleShowLayout>
    </Show>
)

export const ParticipantDatagrid = ({permissions, ...props}) => (
    <Datagrid rowClick="show" expand={<ParticipantExpand/>} {...props}>
        {!props.hide_course &&
            <ReferenceField source="course_iter_id" link="show" reference="iterations">
                <FunctionField render={record => getIterationName(record, true)} />
            </ReferenceField>
        }
        <TextField source="name"/>
        {permissions && permissions.isAdmin() &&
            <ReferenceField source="user_id" reference="users" sortable={false} link="show">
                <TextField source="email" />
            </ReferenceField>
        }
        <AttendancesField source="attendances" sortable={false}/>
        <TextArrayField source="roles" />
        <FunctionField sortable={false} source="in_discord" render={(record,source) =>
            <BooleanField record={{...record, in_discord:!!record.discord_user_id}} source={source}/>}
        />
    </Datagrid>
);

const ResourceList = ({permissions, ...props}) => (
    <List {...props} sort={{ field: 'created_at', order: 'DESC' }}
          bulkActionButtons={false}
          filters={participantsFilters}
          filterDefaultValues={{ alunos: true }}
          pagination={<BigPagination />}
    >
        <ParticipantDatagrid permissions={permissions} />
    </List>
);



export default ResourceList;
