import {
    Button,
    CloneButton,
    Datagrid,
    EditButton,
    EmailField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField,
    TopToolbar,
    FunctionField,
    useRecordContext,
    useResourceContext
} from 'react-admin';
import {PreviewImage} from "../components/HostedImageFieldInput";
import * as React from "react";
import {TextArrayField} from "../components/TextArrayField";
import {AddStaffUserButton} from "./iteration_buttons/AddStaffUserButton";
import LanguageIcon from '@material-ui/icons/Language';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {ApplicationDatagrid, applicationFilters, ApplicationsBulkActionButtons} from "../applications/list";
import {ParticipantDatagrid, ParticipantsBulkActionButtons, participantsFilters} from "../participants/list";
import SendSelectionEmailsButton from "./iteration_buttons/SendSelectionEmailsButton";
import ReferenceList from "../components/ReferenceList";
import {SessionDatagrid, sessionFilters} from "../sessions/list";
import BulkCreateSessionsButton from "./iteration_buttons/BulkCreateSessionsButton";
import ListCustomActions from "../components/ListCustomActions";
import applicationExporter from "../applications/ApplicationExporter";
import {sessionExporter} from "../sessions/SessionExporter";
import LcDateField from "../components/LcDateField";
import {CopyStaffEmails} from "./iteration_buttons/CopyStaffEmails";
import {getIterationName} from "./utils";
import {getApplicationSpecName} from "../application_specs/utils";


const IterationTitle = ({ record }) => {
    return <span>{getIterationName(record, true, false)}</span>;
};

const getCondensedShift = record => record.shift ? (record.shift + 
    (record.shift_schedule ? ` (${record.shift_schedule})` : '')) : '-';

const Aside = ({permissions}) => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const isAdmin = permissions && permissions.isAdmin();
    const isOldAso = record?.course?.program?.slug === 'aso' && record?.application_spec?.slug === 'pre-programa';
    
    return record ? (
        <SimpleShowLayout record={record} resource={resource}>
            <PreviewImage source="image_id" />
            <ReferenceField source="course_id" reference="courses" link="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="iteration_name"/>
            <FunctionField source="shift" render={record => getCondensedShift(record)} />
            <TextField source="course.program.name" />
            { isOldAso && <TextField source="old_id"/> }
            { isAdmin ?
                <ReferenceField source="application_spec_id" reference="application_specs" link="show">
                    <FunctionField render={record => getApplicationSpecName(record)} />
                </ReferenceField> :
                <TextField source="application_spec.edition" />
            }
            <TextField source="calendar_status"/>
            <LcDateField source="start_date" />
            <LcDateField source="end_date" />
        </SimpleShowLayout>
    ) : null;
};

const IterationShowActions = ({ permissions, basePath, data }) => {
    const isOldAso = data?.course?.program?.slug === 'aso' && data?.application_spec?.slug === 'pre-programa';
    return (<TopToolbar>
        {permissions && permissions.isAdmin() &&
            <EditButton basePath={basePath} record={data} />
        }
        {permissions && permissions.isAdmin() &&
            <CloneButton record={data} />
        }
        {isOldAso &&
            <Button
                label="resources.iterations.view_live_button"
                href={`${process.env.REACT_APP_MAIN_WEBSITE_URL}/cursos/${data.course.slug}/${data.iteration_name}`}
                target="_blank"
            >
                <LanguageIcon />
            </Button>
        }
        {isOldAso && permissions && permissions.isAdmin() &&
            <Button
                label="resources.iterations.preview_button"
                href={`${process.env.REACT_APP_MAIN_WEBSITE_URL}/cursos/${data.course.slug}/${data.iteration_name}/preview`}
                target="_blank"
            >
                <VisibilityIcon />
            </Button>
        }
    </TopToolbar>
    );
};

const IterationShow = ({permissions, ...props}) => {
    return (
    <Show
        aside={<Aside permissions={permissions} />}
        {...props}
        component="div"
        actions={<IterationShowActions permissions={permissions}/>}
        title={<IterationTitle />}
    >
        <TabbedShowLayout>
            <Tab label="Participantes">
                <ReferenceList
                    reference="participants"
                    target="course_iter_id"
                    sort={{field: 'created_at', order: 'desc'}}
                    filters={participantsFilters}
                    filterDefaultValues={{ alunos: true }}
                    bulkActionButtons={<ParticipantsBulkActionButtons />}
                >
                    <ParticipantDatagrid hide_course="true" permissions={permissions}/>
                </ReferenceList>
            </Tab>
            <Tab label="Equipa">
                <ReferenceList
                    reference="staff_users"
                    target="course_iter_id"
                    sort={{field: 'name', order: 'asc'}}
                    actions={
                        <ListCustomActions>
                            {permissions && permissions.isAdmin() &&
                                <AddStaffUserButton/>
                            }
                            <CopyStaffEmails />
                        </ListCustomActions>
                    }
                >
                    <Datagrid rowClick={permissions && permissions.isAdmin() ? "edit" : null}>
                        <TextField source="name" />
                        {permissions && permissions.isAdmin() &&
                            <ReferenceField source="user_id" reference="users" sortable={false} link="show">
                                <TextField source="email" />
                            </ReferenceField>
                        }
                        {permissions && !permissions.isAdmin() &&
                            <EmailField label="Email" source="user.email" />
                        }
                        <TextField source="phone"/>
                        <TextArrayField source="roles" />
                        {permissions && permissions.isAdmin() &&
                            <EditButton/>
                        }
                    </Datagrid>
                </ReferenceList>
            </Tab>
            {permissions && permissions.isAdmin() &&
                <Tab label="Candidaturas">
                    <ReferenceList
                        reference="applications"
                        target="course_iter_id"
                        sort={{field: 'created_at', order: 'desc'}}
                        filters={applicationFilters}
                        bulkActionButtons={<ApplicationsBulkActionButtons />}
                        actions={<ListCustomActions><SendSelectionEmailsButton/></ListCustomActions>}
                        exporter={applicationExporter}
                    >
                        <ApplicationDatagrid hide_course="true" />
                    </ReferenceList>
                </Tab>
            }
            <Tab label="Sessões">
                <ReferenceList
                    reference="sessions"
                    target="course_iter_id"
                    sort={{field: 'session_number', order: 'asc'}}
                    filters={sessionFilters}
                    exporter={sessionExporter}
                    actions={
                        <ListCustomActions>
                            {permissions && permissions.isAdmin() &&
                                <BulkCreateSessionsButton/>
                            }
                        </ListCustomActions>}
                >
                    <SessionDatagrid hide_course="true" />
                </ReferenceList>
            </Tab>
        </TabbedShowLayout>
    </Show>
    );
}

export default IterationShow;
