import { downloadCSV } from "react-admin";
import jsonExport from 'jsonexport/dist';
import { chunk, isPlainObject } from 'lodash';

const MAX_PER_REQUEST = 45;

/**
 * Creates a CSV exporter of items passed by react-admin list when clicking the export button
 * These are the currently filtered items (attention: total, not per page)
 * It also receives the following options:
 * - resource: string (e.g. 'applications')
 * - fieldsToExport: Array of fields to include in CSV. Each field can be:
 *     - 'fieldName': used as both header and field name (also supports 'field.nested')
 *     - { 'Header': 'fieldName' }: for custom header
 *     - { 'Header': (item, relatedData) => value }: for computing fields from item (a row) and relatedData
 * - filename: string (defaults to resource)
 * - loadRelatedData: async (items, dataProvider) => Promise<{[key]: any}>: loads additional data passed to field functions
 */
export const createExporter = (options) => {
	const { 
		resource,
		fieldsToExport = [], 
		filename = resource,
		loadRelatedData = async () => ({})
	} = options;

	const exporter = async (items, _, dataProvider, filenameOverride) => {
		const headers = new Set();
		
		const additionalData = await loadRelatedData(items, dataProvider);
		
		// pass the main dataset and the additional data
		const relatedData = { items, ...additionalData };
		
		const processedItems = await Promise.all(items.map(async (item) => {
			const result = {};
			
			await Promise.all(fieldsToExport.map(async (fieldItem) => {
				let field, valueGetter;
				
				if (typeof fieldItem === 'string') {
					// "field" (header = field)
					field = fieldItem;
					valueGetter = (obj) => getValue(obj, field);
				} else {
					const entries = Object.entries(fieldItem);
					field = entries[0][0];
                    
                    if (typeof entries[0][1] === 'string') {
						// "header": "field"
                        valueGetter = (obj) => getValue(obj, entries[0][1]);
                    } else if (typeof entries[0][1] === 'function') {
						// "header": (obj) => ...
                        valueGetter = entries[0][1];
                    } else {
                        throw new Error(`Invalid field item: ${JSON.stringify(fieldItem)}`);
                    }
				}
				
				const value = await valueGetter(item, relatedData);

				if (isPlainObject(value)) {
					// nested fields
					Object.entries(value).forEach(([k, v]) => {
						result[`${field}.${k}`] = valueToString(v);
						headers.add(`${field}.${k}`);
					});
				} else if (value !== undefined) {
					result[field] = valueToString(value);
					// add header only if at least one item has a value for this field;
					// fields with only undefined values will not be added to the CSV
					headers.add(field);
				}
			}));
			
			return result;
		}));

		jsonExport(processedItems, { headers: Array.from(headers) }, (err, csv) => {
			downloadCSV(csv, filenameOverride || filename);
		});
	};
	
	return exporter;
};

//// helper functions

// gets data from the database in chunks of MAX_PER_REQUEST; also deduplicates ids
export const getDataChunked = async (dataProvider, ids, resource, target = 'id', sort = {}) => {
	const uniqueIds = [...new Set(ids)];
	const chunks = chunk(uniqueIds, MAX_PER_REQUEST);
	
	const allRes = await Promise.all(chunks.map(async (group, index) => {
		const response = await dataProvider.getManyReference(
			resource,
			{
				target,
				id: group,
				pagination: { page: 1, perPage: 10000 },
				sort,
			}
		);
		
		console.info(`Retrieving exporter data (${response.data.length}/${response.total} ${resource})`);
		return response;
	}));
	
	return allRes.reduce((acc, curr) => acc.concat(curr.data), []);
};

// converts a value to the string representation used in the CSV file
export const valueToString = (obj) => {
	if (obj === null || obj === undefined) return '';
	if (Array.isArray(obj)) return obj.map(v => valueToString(v)).join('\n');
	return String(obj);
};

// gets a value from an object by a dot-separated path
export const getValue = (obj, field) => {
	return field.split('.').reduce((o, key) => o?.[key], obj);
};


export default createExporter;
