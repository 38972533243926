import React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { FormControl, FormControlLabel, Switch, FormHelperText } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const IsEditableInput = () => {
    const form = useForm();
    const formData = useFormState();
    const translate = useTranslate();

    return (
        <FormControl>
            <FormControlLabel
                label={translate('resources.application_specs.fields.is_editable')}
                control={
                    <Switch
                        disabled={formData.values.application_scope && formData.values.application_scope !== 'application_spec'}
                        checked={formData.values.application_scope === 'application_spec' && formData.values.is_editable}
                        onChange={(e) => form.change('is_editable', e.target.checked)}
                    />
                }
            />  
            <FormHelperText>
                {translate('resources.application_specs.helpers.is_editable')}
            </FormHelperText>
        </FormControl>
    );
};

export default IsEditableInput;
