import { cloneElement, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    sanitizeListRestProps,
    useListContext,
    useResourceContext,
    useResourceDefinition,
} from 'ra-core';
import {FilterContext, TopToolbar, FilterButton, CreateButton, ExportButton} from "react-admin";


const ListCustomActions = props => {
    const { className, exporter, filters: filtersProp, children, ...rest } = props;
    const {
        currentSort,
        displayedFilters,
        filterValues,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);

    const resource = useResourceContext(rest);
    const { hasCreate } = useResourceDefinition(rest);
    const filters = useContext(FilterContext) || filtersProp;
    return useMemo(
        () => (
            <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
                {filtersProp
                    ? cloneElement(filtersProp, {
                        resource,
                        showFilter,
                        displayedFilters,
                        filterValues,
                        context: 'button',
                    })
                    : filters && <FilterButton />}
                {children}
                {hasCreate && <CreateButton basePath={basePath} />}
                {exporter !== false && (
                    <ExportButton
                        disabled={total === 0}
                        resource={resource}
                        sort={currentSort}
                        filterValues={filterValues}
                        exporter={exporter}
                        maxResults={10000}
                    />
                )}
            </TopToolbar>
        ),
        [resource, displayedFilters, filterValues, selectedIds, filters, total, exporter] // eslint-disable-line react-hooks/exhaustive-deps
    );
};

ListCustomActions.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    currentSort: PropTypes.any,
    displayedFilters: PropTypes.object,
    exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    filters: PropTypes.element,
    filterValues: PropTypes.object,
    hasCreate: PropTypes.bool,
    resource: PropTypes.string,
    onUnselectItems: PropTypes.func.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    showFilter: PropTypes.func,
    total: PropTypes.number,
};

ListCustomActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export default ListCustomActions;