import {ReferenceInput, SelectInput} from "react-admin";
import * as React from "react";
import { getIterationName } from "../iterations/utils";

const IterationFilter = ({source, ...props}) => {
    return (
        <ReferenceInput
            perPage={1000}
            source={source}
            reference="iterations"
            sort={{ field: 'start_date', order: 'DESC' }}
            {...props}
        >
            <SelectInput allowEmpty optionText={(choice) => getIterationName(choice, true, true)} />
        </ReferenceInput>
    );
};

IterationFilter.defaultProps = {
    label: "Iteração"
}

export default IterationFilter;