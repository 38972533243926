import { createExporter, getDataChunked } from '../common/createExporter';
import { groupBy } from 'lodash';
import DateFnsAdapter from "@date-io/date-fns";
import defaultLocale from "date-fns/locale/pt";

const dateFns = new DateFnsAdapter({locale: defaultLocale});

// JSON fields 'questions' and 'confirmation_data' are split into multiple columns
// 'course_name' and 'application_spec_name' are computed fields
const fieldsToExport = [
    'created_at',
    // for applications only
    'course_iter_id', 
    { 'course_name': (item) => item.course_iteration?.course.name },
    // for application_submissions only
    'application_spec_id', 
    { 'application_spec_name': (item) => item.application_spec ?
        item.application_spec.program.name + ' ' + item.application_spec.edition : undefined
    },
    // user details/personal data
    'user_id', 'name', 'user.email',
    'phone', 'parent_name', 'parent_email', 'parent_phone', 'birthdate', 'gender',
    'nationality', 'school_year', 'school_name', 'school_group', 'school_location',
    'school_district',
    // history
    { 'student_history': (item, datasets) => {
        const userHistory = datasets.applicationHistory[item.user_id] || [];
        return userHistory.map(x => historyString(x, 'application')).join("\n");
    }},
    { 'staff_history': (item, datasets) => {
        const staffHistory = datasets.staffHistory[item.user_id] || [];
        return staffHistory.map(x => historyString(x, 'staff')).join("\n");
    }},
    'history_t2', 'history_t2_app', 'history_oc',
    // info
    'info_interests', 'info_english', 'info_computer', 'info_internet',
    // application questions
    'questions',
    // confirmation data (for applications only)
    'confirmed', 'confirmation_data',
];

const historyString = (item, type) => {
    let str = '';

    if (item.course_iteration) {
        str += `${item.course_iteration.course.name} ${item.course_iteration.iteration_name}` +
            (item.course_iteration.shift ? ` ${item.course_iteration.shift}` : '');
    }
    else {
        str += `${item.application_spec.program.name} ${item.application_spec.edition}`;
    }

    if (item.created_at) {
        str += ` [${dateFns.formatByString(new Date(item.created_at), "MMMM 'de' yyyy")}]`;
    }

    if (type === 'application') {
        let status = '(candidatou-se)';
        if ('accepted' in item) {
            if (item.accepted) status = '(foi aluno)'
            if (item.comment) status += ` Comentário: ${item.comment}`;
            if (item.recommendation && item.recommendation !== 'neutro') status += ` Recomendação: ${item.recommendation}`;
        }
        return `${str} ${status}`;
    }
    else if (type === 'staff') {
        return `${str} (${item.roles?.join(", ")})`;
    }
    return str;
};

const createApplicationExporter = (resource) => createExporter({ 
    fieldsToExport,
    resource,
    loadRelatedData: async (items, dataProvider) => {
        const userIds = items.map(item => item.user_id);
        
        // get applications
        const applicationData = await getDataChunked(
            dataProvider, 
            userIds, 
            'applications', 
            'user_id'
        );

        // get submissions
        const submissionData = await getDataChunked(
            dataProvider, 
            userIds, 
            'application_submissions', 
            'user_id'
        );
        
        // applications + submissions
        const applicationHistory = groupBy(
            [...applicationData, ...submissionData], 
            item => item.user_id
        );
        
        // remove submissions with an associated application; sort by creation date
        for (const [userId, applications] of Object.entries(applicationHistory)) {
            applicationHistory[userId] = applications
                .filter((app, _, arr) => !app.application_spec_id || !arr.some(a => a.course_iteration?.application_spec_id === app.application_spec_id))
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }
        
        // fetch staff history
        const staffData = await getDataChunked(
            dataProvider, 
            userIds, 
            'staff_users', 
            'user_id'
        );
        
        const staffHistory = groupBy(staffData, item => item.user_id);
        
        return { applicationHistory, staffHistory };
    }
});

const applicationExporter = (items, _, dataProvider, resource = 'applications') => 
    createApplicationExporter(resource)(items, _, dataProvider);

export default applicationExporter;
