import { useRecordContext, Labeled, useTranslate } from "react-admin";
import { Chip, Box, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import { HostedFile } from "./HostedFile";
import React from 'react';

export const FormResponseField = ({ source, questions, renderTitles }) => {
    const record = useRecordContext();
    const translate = useTranslate();
    const answers = record?.[source];

    if (!answers || !questions || questions.length === 0)
        return null;


    const renderField = (field, answer) => {
        // render grid rows as radio/checkboxes fields
        if (field["type"] === "grid") {
            return (<React.Fragment key={`${field["id"]}`}>
                <Box gridColumn="1 / -1" key={`${field["id"]}-label`}><Typography variant="body1">{field["label"]}</Typography></Box>
                {field["rows"].map(row => renderField(
                    {...row, id: `${field["id"]}-${row["id"]}`, multiple: field["multiple"]}, 
                    answers[field["id"]][row["id"]]))
                }
            </React.Fragment>)
        }

        let answerRender;
        if (answer === undefined)
            answerRender = "-";
        else if(field["type"] === "check" && !field["multiple"])
            answerRender = translate(`ra.boolean.${answer}`);
        else if(field["type"] === "tags" || field["multiple"] || (Array.isArray(answer) && answer.length > 1))
            answerRender = answer?.map(tag => (<Chip key={tag} label={tag} style={{ marginRight: '4px' }} />));
        else if(field["type"] === "file")
            answerRender = <HostedFile filename={answer} />;
        else
            answerRender = answer;

        return (
            <Box pr={1} key={field["id"]}>
                <Labeled label={field["label"]}>
                    <span>{answerRender}</span>
                </Labeled>
            </Box>
        );
    }


    return questions.map(section => (
        <Box key={section["title"]} mb={2}>
            {renderTitles && <Typography variant="h6">{section["title"]}</Typography>}
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
            {
                section["fields"].map(field => renderField(field, answers[field["id"]]))
            }
            </Box>
        </Box>
    ));
};

FormResponseField.defaultProps = {
    renderTitles: true,
};

FormResponseField.propTypes = {
    source: PropTypes.string.isRequired,
    questions: PropTypes.array,
    renderTitles: PropTypes.bool,
};
